body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card#project-idea {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 450px;
}

.card-body#project-idea {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-content#project-idea {
  flex: 1;
  overflow-y: auto; /* Vertical scrolling if content overflows */
  padding-bottom: 1rem;
}

.d-flex {
  display: flex !important;
}

.justify-content-end {
  justify-content: space-between !important;
}

.project-edit {
  height: 100%; /* Set height to 100% to match parent height */
  resize: none; /* Disable textarea resizing */
}

.list-group-item:hover {
  background-color: rgb(13 110 253);
  color: #ffffff;
  cursor: pointer;
}

.slider-mark {
  font-size: 11px; /* Adjust the font size as needed */
}

.image-container {
  position: relative;
  display: inline-block;
}

.img-thumbnail {
  display: block; /* Makes sure the image is block-level */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px; /* Text size */
  opacity: 0; /* Initially hide overlay */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.image-container:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}